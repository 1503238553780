import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import EventTypeIcon from './event-type-icons'
import EventActorPhrase from './event-actor-phrase'
import { TimeAgoShort } from './time-ago-short'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline: {
    margin: 0,
    padding: 0,
  },
  image: {
    maxWidth: 360,
    maxHeight: 300,
    objectFit: 'cover',
  },
  timelineOpposite: {
    flex: 0.3,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      backgroundColor: `rgba(255, 255, 255, 0.5)`,
      borderRadius: 5,
      boxShadow: '1px 1px 2px rgba(0,0,0,0.3)',
      right: -10,
      top: 0,
    },
  },
}))

export default function CustomTimeline({ events }) {
  const classes = useStyles()

  return (
    <>
      <Timeline className={classes.timeline}>
        {events.length > 0 ? (
          events.map(({ data: event }) => {
            return (
              <TimelineItem key={event.id}>
                <TimelineOppositeContent className={classes.timelineOpposite}>
                  <Typography variant="body2" color="textSecondary">
                    <TimeAgoShort datetime={event.createdAt} />
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <EventTypeIcon eventType={event.eventType} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="body2" component="h3">
                      <EventActorPhrase
                        eventType={event.eventType}
                        actor={event.actor}
                      />
                    </Typography>
                    <Typography>{event.text}</Typography>
                    {event.image && (
                      <img
                        className={classes.image}
                        src={event.image}
                        alt={event.title}
                      />
                    )}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          })
        ) : (
          <p>No activity yet.</p>
        )}
      </Timeline>
    </>
  )
}

CustomTimeline.propTypes = {
  events: PropTypes.array,
}
