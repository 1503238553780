import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import ActorCard from './actor-card'

const useStyles = makeStyles(theme => {
  return {
    infoRows: {
      '& > tr': {
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  }
})

export default function About({ plantActor }) {
  const classes = useStyles()

  const {
    instance_of: [species],
  } = plantActor

  const {
    genus: [genus],
  } = species

  const {
    family: [family],
  } = genus

  return (
    <div>
      <h2>Information</h2>
      <table>
        <tbody className={classes.infoRows}>
          <tr>
            <th>Name</th>
            <td>{species.Name}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{species.Description}</td>
          </tr>
          <tr>
            <th>Hardiness</th>
            <td>{species.Hardiness}</td>
          </tr>
          <tr>
            <th>CareTip</th>
            <td>{species.CareTip}</td>
          </tr>
          <tr>
            <th>Lighting</th>
            <td>{species.Lighting}</td>
          </tr>
          <tr>
            <th>WateringFrequency</th>
            <td>{species.WateringFrequency}</td>
          </tr>
          <tr>
            <th colSpan={2} style={{ textAlign: 'right' }}>
              Classification
            </th>
          </tr>
          <tr>
            <th>ScientificName</th>
            <td>{species.ScientificName}</td>
          </tr>
          <tr>
            <th>Family</th>
            <td>
              {family.Name} <em>commonly called</em> {family.CommonName}
            </td>
          </tr>
          <tr>
            <th>Genus</th>
            <td>{genus.Name}</td>
          </tr>
        </tbody>
      </table>
      <h3>Other plants from genus "{genus.Name}"</h3>
      {genus.plants.map(plant => (
        <div key={plant.Name}>
          <h4>{plant.Name}</h4>
          <Grid container spacing={3}>
            {plant.plantactors.map(actor => (
              <Grid item key={actor.Name}>
                <ActorCard
                  name={actor.Name}
                  image={actor.ImageSharp.childImageSharp.fixed.src}
                  species={plant.Name}
                  handle={actor.Handle}
                  sideBySide
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  )
}

About.propTypes = {
  plantActor: PropTypes.object,
}
