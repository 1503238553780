import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 600,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile: {
    margin: 0,
    img: {
      objectFit: 'cover',
    },
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}))

export default function Photos({ events }) {
  const classes = useStyles()
  const images = events.filter(event => event.image)
  return (
    <div className={classes.root}>
      <GridList cellHeight={200} spacing={1} className={classes.gridList}>
        {images.map(tile => (
          <GridListTile
            className={classes.gridListTile}
            key={tile.id}
            cols={tile.eventType === 'photo' ? 2 : 1}
            rows={tile.eventType === 'photo' ? 2 : 1}
          >
            <img src={tile.image} alt={tile.text} />
            <GridListTileBar
              title={tile.text}
              titlePosition="bottom"
              className={classes.titleBar}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}

Photos.propTypes = {
  events: PropTypes.array,
}
