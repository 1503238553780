import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import CollectionsIcon from '@material-ui/icons/Collections'

import EcoIcon from '@material-ui/icons/Eco'
import CustomTimeline from './custom-timeline'
import About from './about'
import Photos from './photos'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    padding: '20px 0',
  },
}))

export default function ProfileTabs({ plantActor, events }) {
  const classes = useStyles()
  const [value, setValue] = React.useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="Tabs"
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Feed" icon={<RssFeedIcon></RssFeedIcon>} value="1" />
          <Tab label="About" icon={<EcoIcon></EcoIcon>} value="2" />
          <Tab
            label="Photos"
            icon={<CollectionsIcon></CollectionsIcon>}
            value="3"
          />
        </TabList>
        <TabPanel className={classes.tabPanel} value="1">
          <CustomTimeline plantActor={plantActor} events={events} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          <About plantActor={plantActor}></About>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="3">
          <Photos events={events} />
        </TabPanel>
      </TabContext>
    </div>
  )
}

ProfileTabs.propTypes = {
  plantActor: PropTypes.object.isRequired,
  events: PropTypes.array,
}
