import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import ActorCard from '../components/actor-card'

import ProfileTabs from '../components/tabs'
import { Button, Grid } from '@material-ui/core'
import { isLoggedIn } from '../services/auth'
import { saveEvent } from '../services/admin'
import { eventTypeConfig, useEventApi } from '../services/events'

const PlantProfile = ({
  data: {
    plantsGraph: {
      PlantActor: [plantActor],
    },
  },
}) => {
  return (
    <Layout noH1={true}>
      <SEO title={plantActor.Name} image={''} />
      <article>
        {isLoggedIn() ? (
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            {Object.keys(eventTypeConfig).map(eventType => (
              <Grid item key={eventType} xs>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    saveEvent({
                      action: eventType,
                      actor: plantActor.Handle,
                    })
                  }}
                  variant="contained"
                  color={eventTypeConfig[eventType].color}
                  startIcon={eventTypeConfig[eventType].icon}
                >
                  {eventType}
                </Button>
              </Grid>
            ))}
          </Grid>
        ) : (
          ''
        )}
        <ActorCard
          name={plantActor.Name}
          image={plantActor.ImageSharp.childImageSharp.fixed.src}
          species={plantActor.instance_of[0].Name}
          handle={plantActor.Handle}
          profile
        />
        {useEventApi(({ events }) => (
          <ProfileTabs
            plantActor={plantActor}
            events={events.list({ actor: plantActor.Handle }).data}
          ></ProfileTabs>
        ))}
      </article>
    </Layout>
  )
}

export default PlantProfile

export const pageQuery = graphql`
  query($handle: String!) {
    plantsGraph {
      PlantActor(Handle: $handle) {
        Name
        Image
        ImageSharp {
          childImageSharp {
            fixed(cropFocus: CENTER, fit: COVER, width: 120, height: 120) {
              src
            }
          }
        }
        Handle
        instance_of {
          Name
          CareTip
          Description
          Hardiness
          Lighting
          ScientificName
          WateringFrequency
          genus {
            Name
            family {
              CommonName
              Name
            }
            plants(first: 5) {
              Name
              plantactors(first: 5) {
                Name
                Image
                Handle
                ImageSharp {
                  childImageSharp {
                    fixed(
                      cropFocus: CENTER
                      fit: COVER
                      width: 120
                      height: 120
                    ) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
PlantProfile.propTypes = {
  data: PropTypes.object,
}
